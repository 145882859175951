/**
 * @license
 *
 * Font Family: Clash Display
 * Designed by: Indian Type Foundry
 * URL: https://www.fontshare.com/fonts/clash-display
 * © 2022 Indian Type Foundry
 *
 * Font Styles:
 * Clash Display Variable(Variable font)
 * Clash Display Extralight
 * Clash Display Light
 * Clash Display Regular
 * Clash Display Medium
 * Clash Display Semibold
 * Clash Display Bold
 *
*/


/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 700.0;
*
* available axes:

* 'wght' (range from 200.0 to 700.0)

*/

@font-face {
  font-family: 'ClashDisplay';
  src: url('../fonts/ClashDisplay/ClashDisplay-Variable.woff2') format('woff2'),
       url('../fonts/ClashDisplay/ClashDisplay-Variable.woff') format('woff');
       font-weight: 200 700;
       font-display: swap;
       font-style: normal;
}