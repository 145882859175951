@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('./clash-display.css');
@import url('./manrope.css');

/* @font-face {
  font-family: "Clash Display";
  src: url('./fonts/ClashDisplay/ClashDisplay-Regular.woff2') format('woff2'),
  url('./fonts/ClashDisplay/ClashDisplay-Regular.woff') format('woff'),
  url('./fonts/ClashDisplay/ClashDisplay-Regular.ttf') format('truetype');
  font-weight: 00;
} */

/* @font-face {
font-family: 'ClashDisplay-Variable';
  font-family: 'ClashDisplay-Extralight';
  font-family: 'ClashDisplay-Light';
  font-family: 'ClashDisplay-Regular';
  font-family: 'ClashDisplay-Medium';
  font-family: 'ClashDisplay-Semibold';
  font-family: 'ClashDisplay-Bold';
}

@font-face {
  font-family: 'ClashDisplay';
  src: url('./fonts/ClashDisplay/ClashDisplay-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
} */
 
.fire-link {
  @apply text-inherit hover:text-inherit visited:text-inherit;
}

/*
    https://github.com/tailwindlabs/tailwindcss/discussions/2394
    https://github.com/tailwindlabs/tailwindcss/pull/5732
*/
@layer utilities {

  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none !important;
  }

  .no-scrollbar {
    -ms-overflow-style: none !important;
    /* IE and Edge */
    scrollbar-width: none !important;
    /* Firefox */
  }
}