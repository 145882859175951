
@font-face {
font-family: 'Manrope';
    src: url('../fonts/Manrope/Manrope-ExtraLight.woff2') format('woff2'),
        url('../fonts/Manrope/Manrope-ExtraLight.woff2') format('woff2');
    font-weight: 200;
    font-display: swap;
    font-style: normal;
}


@font-face {
font-family: 'Manrope';
    src: url('../fonts/Manrope/Manrope-Light.woff2') format('woff2'),
        url('../fonts/Manrope/Manrope-Light.woff2') format('woff2');
    font-weight: 300;
    font-display: swap;
    font-style: normal;
}


@font-face {
font-family: 'Manrope';
    src: url('../fonts/Manrope/Manrope-Regular.woff2') format('woff2'),
        url('../fonts/Manrope/Manrope-Regular.woff2') format('woff2');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}


@font-face {
font-family: 'Manrope';
    src: url('../fonts/Manrope/Manrope-Medium.woff2') format('woff2'),
        url('../fonts/Manrope/Manrope-Medium.woff2') format('woff2');
    font-weight: 500;
    font-display: swap;
    font-style: normal;
}


@font-face {
font-family: 'Manrope';
    src: url('../fonts/Manrope/Manrope-SemiBold.woff2') format('woff2'),
        url('../fonts/Manrope/Manrope-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-display: swap;
    font-style: normal;
}


@font-face {
font-family: 'Manrope';
    src: url('../fonts/Manrope/Manrope-Bold.woff2') format('woff2'),
        url('../fonts/Manrope/Manrope-Bold.woff2') format('woff2');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}

@font-face {
font-family: 'Manrope';
    src: url('../fonts/Manrope/Manrope-ExtraBold.woff2') format('woff2'),
        url('../fonts/Manrope/Manrope-ExtraBold.woff2') format('woff2');
    font-weight: 800;
    font-display: swap;
    font-style: normal;
}